<br />
<div class="container" *ngIf="authService.isAdmin$ | async">
    <div class="row">
      <div class="col-sm-3">
        <ul class="list-group">
          <li class="list-group-item hoverable">
            <a [routerLink]="['/admin/order-categories', 'Home Lab Tests']">
              {{ this.translate.data["ORDERCATS_HOME"] | translate }}
            </a>
          </li>
          <li class="list-group-item hoverable">
            <a [routerLink]="['/admin/order-categories', 'General Lab Tests']">
              {{ this.translate.data["ORDERCATS_GENERAL"] | translate }}
            </a>
          </li>
          <li class="list-group-item hoverable">
            <a [routerLink]="['/admin/all-products']">
              {{ this.translate.data["ALLPRODUCTS"] | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sm-9">
        <router-outlet></router-outlet>
      </div>
    </div>
</div>
