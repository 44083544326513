import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from "../../base/index/index.component";
import { AdminComponent } from './admin.component';
import { ProductListComponent } from '../product/product-list/product-list.component';
import { OrderCategoriesComponent } from "./order-categories/order-categories.component";

export const AdminRoutes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        component: AdminComponent,
      },
      {
        path: "order-categories/:category",
        component: OrderCategoriesComponent,
      },
      {
        path: "all-products",
        component: ProductListComponent,
      },
    ]
  }
];

/* @NgModule({
  imports: [RouterModule.forChild(AdminRoutes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
 */