<br />
<div class="container" *ngIf="poArray.length == 0">
  No POs found!
</div>
<div class="container" *ngIf="poArray.length > 0 && authService.isLoggedIn$ | async">
  <h4>{{ title }}</h4>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
  
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
  
      <!-- Progress Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
        <td mat-cell *matCellDef="let row"> {{row.totalPrice}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.purchaserName}} </td>
      </ng-container>
  
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let row"> {{row.purchaseDate | date}} </td>
      </ng-container>
  
      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row" [style.color]="PAYMENT_COLORS[row.paymentStatus]">
          {{row.paymentStatus}}
          <div *ngIf="authService.isAdmin$ | async">
            <a class="nav-link" href="javascript:;;" (click)="openDialog(row)">
              <i class="fa fa-edit"></i>
            </a>
          </div>
        </td>
      </ng-container>
  
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let row">
          <a
            class="nav-link"
            href="javascript:;;"
            [routerLink]="['/checkouts', { outlets: { checkOutlet: ['result', row.$key] } }]"
          >
            <i class="fa fa-eye"></i>
          </a>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  
    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </div>  
</div>
