import { Component, OnInit } from "@angular/core";
import { Product } from "../../../../shared/models/product";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../shared/services/auth.service";
import { ProductService } from "../../../../shared/services/product.service";
import { ToastrService } from "src/app/shared/services/toastr.service";

export interface Hero {
  id: number;
  name: string;
}
export const HEROES: Hero[] = [
  { id: 11, name: 'Dr Nice' },
  { id: 12, name: 'Narco' },
  { id: 13, name: 'Bombasto' },
  { id: 14, name: 'Celeritas' },
  { id: 15, name: 'Magneta' },
  { id: 16, name: 'RubberMan' },
  { id: 17, name: 'Dynama' },
  { id: 18, name: 'Dr IQ' },
  { id: 19, name: 'Magma' },
  { id: 20, name: 'Tornado' }
];

type ProdCatArray = Array<{category: string, products: Array<Hero>}>;

const LISTVIEW_PRODUCTORDER: ProdCatArray = [
  { category: "cat1", products: [ { id: 11, name: 'Dr Nice' }, { id: 12, name: 'Narco' } ] },
  { category: "cat2", products: [ { id: 13, name: 'Bombasto' }, { id: 14, name: 'Celeritas' } ] },
  { category: "cat3", products: [ { id: 15, name: 'Magneta' }, { id: 16, name: 'RubberMan' } ] },
  { category: "cat4", products: [ { id: 17, name: 'Dynama' }, { id: 18, name: 'Dr IQ' } ] },
];

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements OnInit {
  private sub: any;
  productList: Product[];
  selectedProductList: Product[];
  loading = false;
  // brands = ["All", "Apple", "Realme", "Nokia", "Motorolla"];

  // selectedBrand: "All";
  page = 1;

  heroes = HEROES;
  selectedHero?: Hero;

  categoryProducts = LISTVIEW_PRODUCTORDER;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private productService: ProductService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params.category) {
        // console.log(params.category);
        const category = params.category; // (+) converts string 'id' to a number
        this.getCategorizedProducts(category);
      }
      else {
        this.getAllProducts();
      }
    });
  }

  getAllProducts() {
    this.loading = true;
    const x = this.productService.getProducts();
    x.snapshotChanges().subscribe(
      (product) => {
        this.loading = false;
        this.productList = [];
        product.forEach((element) => {
          const y = { ...element.payload.toJSON(), $key: element.key };
          this.productList.push(y as Product);
        });
        this.updateSelectionFromSource();
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
  }

  getCategorizedProducts(category: string) {
    this.loading = true;
    const x = this.productService.getProductsByCategory(category);
    x.snapshotChanges().subscribe(
      (product) => {
        this.loading = false;
        this.productList = [];
        product.forEach((element) => {
          const y = { ...element.payload.toJSON(), $key: element.key };
          this.productList.push(y as Product);
        });
        this.updateSelectionFromSource();
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
  }

  updateSelectionFromSource() {
    this.selectedProductList = [...this.productList];
  }

  editProduct(key: string) {
    var inputElement = <HTMLInputElement>document.getElementById('editID');
    inputElement.value = key;
  }

  removeProduct(key: string) {
    this.productService.deleteProduct(key);
  }

  addFavourite(product: Product) {
    this.productService.addFavouriteProduct(product);
  }

  addToCart(product: Product) {
    this.productService.addToCart(product);
  }

  onSelect(hero: Hero): void {
    this.selectedHero = hero;
  }
}
