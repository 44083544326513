export const FireBaseConfig = {
  apiKey: "AIzaSyDDqbo2TDARJ-xo3lQXmc6VS9kmuqmPiBs",
  authDomain: "anglidiagnostics.firebaseapp.com",
  databaseURL: "https://anglidiagnostics-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "anglidiagnostics",
  storageBucket: "anglidiagnostics.appspot.com",
  messagingSenderId: "42343295115",
  appId: "1:42343295115:web:1b9fdfbd2d66c9996e0c8e",
  measurementId: "G-NKW7FCYHKR"
};
