import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ProductService } from "src/app/shared/services/product.service";
import { Product } from "src/app/shared/models/product";

declare var $: any;
declare var require: any;
declare var toastr: any;
const shortId = require("shortid");
const moment = require("moment");

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})
export class AddProductComponent implements OnInit {
  product: Product = new Product();
  editID: string = "";
  currentlyFocused: boolean = false;
  constructor(private productService: ProductService) {}

  ngOnInit() { }

  createProduct(productForm: NgForm) {
    var productID = $('#editID').val();
    if (typeof productID!='undefined' && productID) {
      this.productService.updateProduct(this.product, () => {
        $("#exampleModalLong").modal("hide");
        toastr.success(
          "product " + this.product.productName + "is updated successfully",
          "Product Updation"
        );
        this.currentlyFocused = false;
      }, () => {
        toastr.error('Error occurred');
      });
    }
    else {
      const payload: Product = {
        ...productForm.value,
        productId: "PROD_" + shortId.generate(),
        productAdded: moment().unix(),
        ratings: Math.floor(Math.random() * 5 + 1),
        favourite: false,
      };
      this.productService.createProduct(payload, () => {
        this.product = new Product();
        $("#exampleModalLong").modal("hide");
        toastr.success(
          "product " + payload.productName + "is added successfully",
          "Product Creation"
        );
        this.currentlyFocused = false;
      }, () => {
        toastr.error('Error occurred');
      });
    }
  }

  onFormFocus() {
    if (this.currentlyFocused === false) {
      var productID = $('#editID').val();
      if (typeof productID!='undefined' && productID) {
        var productObservable = this.productService.getProductById(productID);
        productObservable.snapshotChanges().subscribe(
          (productSnapshot) => {
            var productFromSnapshot = productSnapshot.payload.val();
            document.getElementById('exampleModalLongTitle').innerText = 'Edit Test';
            document.getElementById('productSubmit').innerText = 'Update Test';
            this.product = productFromSnapshot;
            this.product.$key = productSnapshot.key;
          }
        );
      }
      this.currentlyFocused = true;
    }
  }

  onFormClose() {
    this.currentlyFocused = false;
  }
}
