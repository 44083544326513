import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { database } from "firebase";
import { PurchaseOrder } from "../models/purchaseorder";
import { ToastrService } from "./toastr.service";

@Injectable()
export class PurchaseOrderService {
  purchaseOrders: AngularFireList<PurchaseOrder>;
  purchaseOrder: AngularFireObject<PurchaseOrder>;

  constructor(
    private db: AngularFireDatabase,
    private toastrService: ToastrService
  ) {}

  getPurchaseOrders() {
    this.purchaseOrders = this.db.list("purchaseorders");
    return this.purchaseOrders;
  }

  getPurchaseOrderById(key: string) {
    this.purchaseOrder = this.db.object("purchaseorders/" + key);
    return this.purchaseOrder;
  }

  createPurchaseOrder(data: PurchaseOrder, callback: (createdData) => void, errorback: (err) => void) {
    this.purchaseOrders = this.db.list("purchaseorders");
    this.purchaseOrders.push(data).then( callback ).catch( errorback );
    //callback();
    // .push() also creates a new unique key, which can be accessed with ref.key
    // let ref: Reference = this.products.push();
    // ref.set(data)
    // .then( callback() )
    // .catch( errorback() );
  }

  updatePurchaseOrder(data: PurchaseOrder, callback: (updatedData) => void, errorback: (err) => void) {
    this.purchaseOrders = this.db.list("purchaseorders");
    this.purchaseOrders.update(data.$key, {
      id: data.id,
      products: data.products,
      productsPrice: data.productsPrice,
      tax: data.tax,
      totalPrice: data.totalPrice,
      purchaseDate: data.purchaseDate,
      purchaserKey: data.purchaserKey,
      purchaserName: data.purchaserName,
      paymentStatus: data.paymentStatus
    }).then( callback ).catch( errorback );
  }

  deletePurchaseOrder(key: string) {
    this.purchaseOrders = this.db.list("purchaseorders");
    this.purchaseOrders.remove(key);
  }

}
