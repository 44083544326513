import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { database } from "firebase";
import { ProductCategoryOrder } from "../models/productcategoryorder";
import { AuthService } from "./auth.service";
import { ToastrService } from "./toastr.service";

@Injectable()
export class ProductCategoryOrderService {
  productCategoryOrders: AngularFireList<ProductCategoryOrder>;
  productCategoryOrder: AngularFireObject<ProductCategoryOrder>;

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  getProductCategoryOrders() {
    this.productCategoryOrders = this.db.list("productcategoryorders");
    return this.productCategoryOrders;
  }

  getProductCategoryOrderById(id: string) {
    this.productCategoryOrders = this.db.list("productcategoryorders");
    return this.productCategoryOrders.query.orderByChild("id").equalTo(id);
  }

  createOrUpdateProductCategoryOrder(data: ProductCategoryOrder, callback: () => void, errorback: () => void) {
    // this.productCategoryOrders.query.orderByChild("id").equalTo(data.id).once("value", snapshot => {
    //   if (snapshot.exists()) {
    //     this.setProductCategoryOrder(data, callback, errorback);
    //   } else {
    //     this.createProductCategoryOrder(data, callback, errorback);
    //   }
    // })
    if (data.$key != undefined) {
      this.updateProductCategoryOrder(data, callback, errorback);
    }
    else {
      this.createProductCategoryOrder(data, callback, errorback);
    }
  }

  createProductCategoryOrder(data: ProductCategoryOrder, callback: () => void, errorback: () => void) {
    delete data.$key;
    this.productCategoryOrders = this.db.list("productcategoryorders");
    this.productCategoryOrders.push(data).then( callback ).catch( errorback );
  }

  updateProductCategoryOrder(data: ProductCategoryOrder, callback: () => void, errorback: () => void) {
    this.productCategoryOrders.update(data.$key, {
      id: data.id,
      categories: data.categories
    }).then( callback ).catch( errorback );
  }

  deleteProductCategoryOrder(key: string) {
    this.productCategoryOrders.remove(key);
  }

}
