<!-- Carousel -->
<div class="container">
  <!--Carousel Wrapper-->
  <div
    id="carousel-example-2"
    class="carousel slide carousel-fade"
    data-ride="carousel"
  >
    <!--Indicators-->
    <ol class="carousel-indicators">
      <li
        data-target="#carousel-example-2"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carousel-example-2" data-slide-to="1"></li>
      <li data-target="#carousel-example-2" data-slide-to="2"></li>
    </ol>
    <!--/.Indicators-->
    <!--Slides-->
    <div class="carousel-inner" role="listbox">
      <div
        *ngFor="let carouselItem of carouselList; let i = index"
        class="carousel-item {{ i === 0 ? 'active' : '' }}"
      >
        <div class="view">
          <img
            class="d-block w-100"
            [src]="carouselItem.bannerImg"
            alt="First slide"
          />
          <div class="mask rgba-black-light"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">{{ carouselItem.title }}</h3>
          <p>{{ carouselItem.description }}</p>
        </div>
      </div>
    </div>
    <!--/.Slides-->
    <!--Controls-->
    <a
      class="carousel-control-prev"
      href="#carousel-example-2"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carousel-example-2"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
    <!--/.Controls-->
  </div>
  <!--/.Carousel Wrapper-->
  <br />
  <!-- <app-best-product></app-best-product> -->
</div>
