import { AuthGuard } from "./../../../shared/guards/auth_gaurd";
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from "../../base/index/index.component";
import { PoComponent } from './po.component';

export const PoRoutes: Routes = [
  {
    path: "",
    component: PoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: PoComponent,
      },
    ]
  }
];