<!--Section: Products v.2-->
<section class="section pb-3">
  <div class="row">
    <div class="col-md-6">
      <blockquote class="blockquote float-left bq-primary">
        <h3 class="bq-title">
          {{ translate.data["BESTPRODUCTS"] | translate }}
        </h3>
      </blockquote>
    </div>
    <div class="col-md-6">
      <a
        class="btn btn-primary waves-light float-right"
        [routerLink]="['/products/all-products']"
        >{{ translate.data["VIEWALL"] | translate }}</a
      >
    </div>
  </div>

  <div *ngIf="loading">
    <app-card-loader [loop]="4" [height]="500"></app-card-loader>
  </div>

  <owl-carousel
    *ngIf="!loading"
    [options]="options"
    [items]="bestProducts"
    [carouselClasses]="['owl-theme', 'row', 'sliding']"
  >
    <!--First column-->
    <div
      class="item"
      style="padding: 20px;"
      *ngFor="let product of bestProducts; let i = index"
    >
      <div class="mb-r">
        <!--Card-->
        <div class="card card-cascade wider">
          <!--Card image-->
<!--           <a [routerLink]="['/products/product', product.$key]">
            <div class="view overlay hm-white-slight">
              <img
                [src]="product.productImageUrl"
                class="img-fluid"
                alt=""
                width="360px"
                height="640px"
              />
              <a>
                <div
                  class="mask waves-effect waves-light"
                  mdbRippleRadius
                ></div>
              </a>
            </div>
          </a> -->
          <!--/.Card image-->

          <!--Card content-->
          <div class="card-body text-center no-padding">
            <!--Category & Title-->
<!--             <a href="" class="text-muted">
              <h5>{{ product.productCategory }}</h5>
            </a>
 -->            <p class="card-title">
              <strong>
                <a
                  [routerLink]="['/products/categorized', product.productCategory]"
                  routerLinkActive="router-link-active"
                  >{{ product.productCategory }}</a
                >
              </strong>
            </p>
            <h5 class="card-title">
              <strong>
                <a [routerLink]="['/products/product', product.$key]">{{
                  product.productName
                }}</a>
              </strong>
            </h5>

            <!--Description-->
            <!-- <p class="card-text">{{ product.productDescription }}</p> -->

            <!--Card footer-->
            <div class="card-footer">
              <span class="left">₹ {{ product.productPrice }}</span>
              <span class="right">
<!--                 <a
                  placement="top"
                  mdbTooltip="Add to Wishlist"
                  container="body"
                >
                  <i class="fa fa-heart"></i>
                </a> -->
                <a
                placement="top"
                mdbTooltip="Add Test"
                container="body"
                (click)="addToCart(product)"
                >
                  <i class="fa fa-shopping-cart"></i>
                </a>
              </span>
            </div>
          </div>
          <!--/.Card content-->
        </div>
        <!--/.Card-->
      </div>
      <!--/First column-->
    </div>
  </owl-carousel>
</section>
<!--Section: Products v.2-->
