<div class="container mt-3">
  <div class="col-md order-md-1">
    <h4 class="mb-3">Shipping Address</h4>
    <form
      class="needs-validation"
      #userDetailsForm="ngForm"
      (ngSubmit)="updateUserDetails(userDetailsForm)"
    >
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="firstName">First name</label>
          <input
            type="text"
            class="form-control"
            id="firstName"
            [(ngModel)]="userDetail.firstName"
            name="firstName"
            required
          />
          <div class="invalid-feedback">
            Valid first name is required.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="lastName">Last name</label>
          <input
            type="text"
            class="form-control"
            id="lastName"
            [(ngModel)]="userDetail.lastName"
            name="lastName"
            required
          />
          <div class="invalid-feedback">
            Valid last name is required.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="email"
          >Email
          <span class="text-muted">(Optional)</span>
        </label>
        <input
          type="email"
          class="form-control"
          id="email"
          [(ngModel)]="userDetails.emailId"
          name="email"
          [disabled]="true"
        />
        <div class="invalid-feedback">
          Please enter a valid email address for shipping updates.
        </div>
      </div>

      <div class="mb-3">
        <label for="address">Address</label>
        <input
          type="text"
          class="form-control"
          id="address"
          placeholder="1234 Main St"
          [(ngModel)]="userDetail.address1"
          name="address1"
          required
        />
        <div class="invalid-feedback">
          Please enter your shipping address.
        </div>
      </div>

      <div class="mb-3">
        <label for="address2"
          >Address 2
          <span class="text-muted">(Optional)</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="address2"
          placeholder="Apartment or suite"
          [(ngModel)]="userDetail.address2"
          name="address2"
        />
      </div>

      <div class="row">
        <div class="col-md-5 mb-3">
          <label for="country">Country</label>
          <select
            class="custom-select d-block w-100"
            id="country"
            required
            [(ngModel)]="userDetail.country"
            name="country"
          >
            <option value="">Choose...</option>
            <option>United States</option>
          </select>
          <div class="invalid-feedback">
            Please select a valid country.
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="state">State</label>
          <select
            class="custom-select d-block w-100"
            id="state"
            [(ngModel)]="userDetail.state"
            name="state"
            required
          >
            <option value="">Choose...</option>
            <option>California</option>
          </select>
          <div class="invalid-feedback">
            Please provide a valid state.
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="zip">Zip</label>
          <input
            type="text"
            class="form-control"
            id="zip"
            placeholder=""
            [(ngModel)]="userDetail.zip"
            name="zip"
            required
          />
          <div class="invalid-feedback">
            Zip code required.
          </div>
        </div>
      </div>
      <hr class="mb-4" />
      <button class="btn btn-primary btn-lg btn-block" type="submit">
        Continue to checkout
      </button>
    </form>
  </div>
</div>
