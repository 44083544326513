import { Component, OnInit } from "@angular/core";
import { Product } from "../../../../shared/models/product";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../shared/services/auth.service";
import { ProductService } from "../../../../shared/services/product.service";
import { ProductCategoryOrderService } from 'src/app/shared/services/productcategoryorder.service';
import { ToastrService } from "src/app/shared/services/toastr.service";
import { ProductCategoryOrder } from 'src/app/shared/models/productcategoryorder';

export interface Hero {
  id: number;
  name: string;
}
export const HEROES: Hero[] = [
  { id: 11, name: 'Dr Nice' },
  { id: 12, name: 'Narco' },
  { id: 13, name: 'Bombasto' },
  { id: 14, name: 'Celeritas' },
  { id: 15, name: 'Magneta' },
  { id: 16, name: 'RubberMan' },
  { id: 17, name: 'Dynama' },
  { id: 18, name: 'Dr IQ' },
  { id: 19, name: 'Magma' },
  { id: 20, name: 'Tornado' }
];

const CATEGORY_ORDER: string[] = [
  "Whole Blood analysis",
  "Serum Analysis",
  "Urine Analysis"
];

type ProdCatArray = Array<{category: string, products: Array<Product>}>;

// const LISTVIEW_PRODUCTORDER: ProdCatArray = [
//   { category: "cat1", products: [ { id: 11, name: 'Dr Nice' }, { id: 12, name: 'Narco' } ] },
//   { category: "cat2", products: [ { id: 13, name: 'Bombasto' }, { id: 14, name: 'Celeritas' } ] },
//   { category: "cat3", products: [ { id: 15, name: 'Magneta' }, { id: 16, name: 'RubberMan' } ] },
//   { category: "cat4", products: [ { id: 17, name: 'Dynama' }, { id: 18, name: 'Dr IQ' } ] },
// ];

@Component({
  selector: "app-product-tile",
  templateUrl: "./product-tile.component.html",
  styleUrls: ["./product-tile.component.scss"],
})
export class ProductTileComponent implements OnInit {
  private sub: any;
  productList: Product[];
  selectedProductList: Product[];
  loading = false;
  homeVisit = false;
  showMenu = false;
  // brands = ["All", "Apple", "Realme", "Nokia", "Motorolla"];

  // selectedBrand: "All";
  page = 1;

  heroes = HEROES;
  selectedHero?: Hero;

  categoryProducts: ProdCatArray = [];
  categorizedProducts: { [key: string]: Array<Product> } = {};
  selectedProducts: Product[] = [];
  productSelectionStates: { [key: string]: boolean } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private productService: ProductService,
    private productCategoryOrderService: ProductCategoryOrderService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.homeVisit = false;
      this.showMenu = false;
      if (params.category) {
        // console.log(params.category);
        const category = params.category; // (+) converts string 'id' to a number
        if ('Home Lab Tests' != category) {
          this.getCategorizedProducts(category);
        }
        else {
          this.homeVisit = true;
        }
      }
      else {
        this.getAllProducts();
      }
    });
  }

  getAllProducts() {
    this.loading = true;
    const x = this.productService.getProducts();
    x.snapshotChanges().subscribe(
      (product) => {
        this.loading = false;
        this.productList = [];
        product.forEach((element) => {
          const y = { ...element.payload.toJSON(), $key: element.key };
          this.productList.push(y as Product);
        });
        this.updateSelectionFromSource();
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
  }

  getCategorizedProducts(category: string) {
    this.loading = true;
    const x = this.productService.getProductsByCategory(category);
    x.snapshotChanges().subscribe(
      (products) => {
        this.loading = false;
        this.productList = [];
        products.forEach((product) => {
          const y = { ...product.payload.toJSON(), $key: product.key };
          this.productList.push(y as Product);
        });
        this.updateSelectionFromSource(category);
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
  }

  updateSelectionFromSource(category: string = undefined) {
    this.categoryProducts.length = 0;
    this.selectedProductList = [...this.productList];
    this.categorizedProducts = {};
    const allProductCategoryOrders = this.productCategoryOrderService.getProductCategoryOrders();
    allProductCategoryOrders.snapshotChanges().subscribe(
      (categoryOrders) => {
        categoryOrders.forEach((element) => {
          const categoryOrder = { ...element.payload.toJSON(), $key: element.key};
          const item = (categoryOrder as ProductCategoryOrder);
          if (category != undefined && item.id === category) {
            Object.values(item.categories).forEach( (categoryFromDB) => {
              //this.categorizedProducts[categoryFromDB] = [];
              var filteredProducts = this.selectedProductList.filter( (product) => { return product.productSubCategory == categoryFromDB});
              if (filteredProducts.length > 0) {
                this.categoryProducts.push( {category: categoryFromDB, products: filteredProducts} );
                filteredProducts.forEach((product) => {
                  this.productSelectionStates[product.productId] = false;
                })
              }
            });
            // this.selectedProductList = [...this.productList];
            // this.selectedProductList.forEach((product) => {
            //   if (product.productSubCategory in this.categorizedProducts) {
            //     this.categorizedProducts[product.productSubCategory].push(product);
            //   }
            //   else {
            //     this.categorizedProducts[product.productSubCategory] = [product];
            //   }
            //   this.productSelectionStates[product.productId] = false;
            // });
          }
        })
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
/*    this.selectedProductList = [...this.productList];
     for (var index in CATEGORY_ORDER) {
      var filteredProducts = this.selectedProductList.filter( (product) => { return product.productSubCategory == CATEGORY_ORDER[index]});
      if (filteredProducts.length > 0) {
        this.categoryProducts.push( {category: CATEGORY_ORDER[index], products: filteredProducts} );
      }
    }
    this.selectedProductList.forEach((product) => {
      if (product.productSubCategory in this.categorizedProducts) {
        this.categorizedProducts[product.productSubCategory].push(product);
      }
      else {
        this.categorizedProducts[product.productSubCategory] = [product];
      }
      this.productSelectionStates[product.productId] = false;
    }); */
  }

  removeProduct(key: string) {
    this.productService.deleteProduct(key);
  }

  addFavourite(product: Product) {
    this.productService.addFavouriteProduct(product);
  }

  addToCart(product: Product) {
    this.productService.addToCart(product);
  }

  onSelect(product: Product): void {
    var index = this.selectedProducts.indexOf(product, 0);
    if ( index == -1) {
      this.selectedProducts.push(product);
    }
    else {
      this.selectedProducts.splice(index, 1);
    }
  }

  bookTestsAndCheckout() {
    //this.selectedProducts.forEach( (product) => {
    //  this.productService.addToCart(product);
    //});
    this.productService.addProductsToCart(this.selectedProducts);
    this.router.navigate(['/products/cart-items']);
  }

  clearAll() {
    this.selectedProducts.splice(0, this.selectedProducts.length);
    for (let key in this.productSelectionStates) {
      this.productSelectionStates[key] = false;
    }
  }
}
