import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { SharedModule } from "../../../shared/shared.module";

import { PoComponent } from './po.component';
import { PoDialogComponent } from './po-dialog/po-dialog-component';

// configuration and services
import { PoRoutes } from "./po.routing";

@NgModule({
  declarations: [
    PoComponent,
    PoDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(PoRoutes),
    SharedModule,
  ]
})
export class PoModule { }
