import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { Product } from "../../../../shared/models/product";
import { AuthService } from "../../../../shared/services/auth.service";
import { PurchaseOrder } from "../../../../shared/models/purchaseorder";
import { ProductService } from "../../../../shared/services/product.service";
import { PurchaseOrderService } from "../../../../shared/services/purchaseorder.service";
import { ToastrService } from "src/app/shared/services/toastr.service";

const shortId = require("shortid");

@Component({
  selector: "app-cart-calculator",
  templateUrl: "./cart-calculator.component.html",
  styleUrls: ["./cart-calculator.component.scss"],
})
export class CartCalculatorComponent implements OnInit, OnChanges {
  @Input() products: Product[];

  totalValue = 0;
  constructor(public authService: AuthService,
    private productService: ProductService,
    private purchaseOrderService: PurchaseOrderService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const dataChanges: SimpleChange = changes.products;

    const products: Product[] = dataChanges.currentValue;
    this.totalValue = 0;
    products.forEach((product) => {
      this.totalValue += product.productPrice;
    });
  }

  ngOnInit() {}

  removeCartProduct(product: Product) {
    this.productService.removeLocalCartProduct(product);
    this.products = this.productService.getLocalCartProducts();
  }

  initiatePurchase() {
    // Save purchase order
    var purchaseOrder = new PurchaseOrder();
    purchaseOrder.id = "ADPO_" + shortId.generate(),
    purchaseOrder.products = [];
    purchaseOrder.productsPrice = 0;
    this.products.forEach((product) => {
      purchaseOrder.products.push(product.$key);
      purchaseOrder.productsPrice += product.productPrice;
    });
    purchaseOrder.tax = 0.12 * purchaseOrder.productsPrice;
    purchaseOrder.totalPrice = purchaseOrder.productsPrice + purchaseOrder.tax;
    purchaseOrder.purchaseDate = Date.now();
    purchaseOrder.paymentStatus = "Pending";
    this.authService.loggedInUserKey.subscribe( (val) => {
      purchaseOrder.purchaserKey = val;
    })
    this.authService.loggedInUserName.subscribe( (val) => {
      purchaseOrder.purchaserName = val;
    })
    this.purchaseOrderService.createPurchaseOrder(purchaseOrder, (createdPO) => {
      this.productService.clearLocalCartProducts();
      this.toastrService.success("Purchase Order Creation Status", "Successfully created Purchase Order - " + purchaseOrder.id);
      this.router.navigate([
        "checkouts",
        { outlets: { checkOutlet: ["result", createdPO.key] }  },
      ]);
    }, () => {
      this.toastrService.error("Purchase Order Creation Status", "Error while creating Purchase Order!");
    });
  }

}
