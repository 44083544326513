<div class="dialog-container">
  <h1 mat-dialog-title>Payment status Update</h1>
  <div mat-dialog-content>
    <p>Purchase Order <b>{{data.poID}}</b>'s current payment status is {{data.currentStatus}}</p>
    <p>Do you want to change this payment status?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data.poID" cdkFocusInitial>Yes</button>
    <button mat-button (click)="onNoClick()">No</button>
  </div>
</div>
