import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imaging-radiology',
  templateUrl: './imaging-radiology.component.html',
  styleUrls: ['./imaging-radiology.component.scss']
})
export class ImagingRadiologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
