<br />
<div class="container">
  <div class="row" *ngIf="authService.isAdmin$ | async">
    <div class="col-sm">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary waves-light"
          data-toggle="modal"
          data-target="#exampleModalLong"
        >
          Create Test
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-md-3">
      <div class="form-group">
        <label for="brand_select">By Types:</label> -->
        <!-- <select
          class="form-control"
          name=""
          id="brand_select"
          [(ngModel)]="selectedBrand"
        >
          <option *ngFor="let brand of brands" [ngValue]="brand">{{
            brand
          }}</option>
        </select> -->
      <!-- </div> -->
      <!-- <ul class="list-group">
        <li class="list-group-item">Cras justo odio</li>
        <li class="list-group-item">Dapibus ac facilisis in</li>
        <li class="list-group-item">Morbi leo risus</li>
      </ul> -->
      <!-- <h2>My Heroes</h2>
      <ul class="heroes">
        <li *ngFor="let hero of heroes"
          [class.selected]="hero === selectedHero"
          (click)="onSelect(hero)">
          <span class="badge">{{hero.id}}</span> {{hero.name}}
        </li>
      </ul>
      <div *ngFor="let hero of heroes">
        <input type="checkbox" (click)="onSelect(hero)" [id]="hero.id" />
        <label [for]="hero.id">{{hero.name}}</label>
      </div>
      
      <div *ngIf="selectedHero">
      
        <h2>{{selectedHero.name | uppercase}} Details</h2>
        <div><span>id: </span>{{selectedHero.id}}</div>
        <div>
          <label for="hero-name">Hero name: </label>
          <input id="hero-name" [(ngModel)]="selectedHero.name" placeholder="name">
        </div>
      
      </div> -->
      <!-- <div *ngFor="let product of selectedProductList">
        <input type="checkbox" (click)="onSelect(product)" [id]="product.productId" />
        <label [for]="product.id">{{product.productName}}</label>
      </div>
      <div *ngFor="let categoryProduct of categoryProducts">
        <label>{{categoryProduct.category}}</label>
        <div *ngFor="let hero of categoryProduct.products">
          <input type="checkbox" (click)="onSelect(hero)" [id]="hero.id" />
          <label [for]="hero.id">{{hero.name}}</label>
        </div>
      </div> -->
    <!-- </div> -->
    <!-- <div class="col-md-9"> -->
    <div class="col-md-12">
      <div class="container">
        <app-card-loader
          [loop]="3"
          [height]="800"
          *ngIf="loading"
        ></app-card-loader>
        <div class="row" *ngIf="!loading">
          <!-- List of Products -->
          <!-- <div
            class="col-sm-4 mb-2"
            *ngFor="
              let product of productList
                | filterByBrand: selectedBrand
                | paginate: { itemsPerPage: 6, currentPage: page }
            "
          > -->
          <div
            class="col-sm-4 mb-2"
            *ngFor="
              let product of selectedProductList
                | paginate: { itemsPerPage: 9, currentPage: page }
            "
          >
            <div class="item">
              <div class="mb-r">
                <!--Card-->
                <div class="card card-cascade wider">
                  <!--Card image-->
                  <!--<a [routerLink]="['/products/product', product.$key]">
                    <div class="view overlay hm-white-slight">
                      <img
                        [src]="product.productImageUrl"
                        class="img-fluid"
                        alt=""
                        width="360px"
                        height="640px"
                      />
                      <a>
                        <div
                          class="mask waves-effect waves-light"
                          mdbRippleRadius
                        ></div>
                      </a>
                    </div>
                  </a>-->
                  <!--/.Card image-->
                  <!--Card content-->
                  <div class="card-body text-center no-padding">
                    <!--Category & Title-->
<!--                     <a class="text-muted">
                      <h5>{{ product.productCategory }}</h5>
                    </a> -->
                    <p class="card-title">
                      <strong>
                        <a
                          [routerLink]="['/products/categorized', product.productCategory]"
                          routerLinkActive="router-link-active"
                          >{{ product.productCategory }}</a
                        >
                      </strong>
                    </p>
                    <p class="card-title">
                      <strong>
                        <a
                          [routerLink]="['/products/product', product.$key]"
                          routerLinkActive="router-link-active"
                          >{{ product.productName }}</a
                        >
                      </strong>
                    </p>
                    <!--Description
                    <p class="card-text">{{ product.productDescription }}</p>-->
                    <!-- Add Test link -->
<!--                     <p class="card-title">
                      <a
                      placement="top"
                      mdbTooltip="Add Test"
                      container="body"
                      (click)="addToCart(product)"
                      >
                      Add Test
                      </a>
                    </p> -->
                    <!--Card footer-->
                    <div class="card-footer">
                      <span class="left">₹ {{ product.productPrice }}</span>
                      <span class="right">
<!--                         <a
                          placement="top"
                          mdbTooltip="Add to Wishlist"
                          container="body"
                          (click)="addFavourite(product)"
                        >
                          <i class="fa fa-heart"></i>
                        </a>
                        <a
                          placement="top"
                          mdbTooltip="Add Test"
                          container="body"
                          (click)="addToCart(product)"
                        >
                          <i class="fa fa-shopping-cart"></i>
                        </a> -->
                        <a
                          placement="top"
                          mdbTooltip="Edit Test"
                          container="body"
                          *ngIf="authService.isAdmin$ | async"
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                          (click)="editProduct(product.$key)"
                        >
                          <i class="fa fa-edit"></i>
                        </a>
                        <a
                          placement="top"
                          mdbTooltip="Remove Test"
                          container="body"
                          *ngIf="authService.isAdmin$ | async"
                          (click)="removeProduct(product.$key)"
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <!--/.Card content-->
                </div>
                <!--/.Card-->
              </div>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <div class="text-center mt-3">
          <pagination-controls
            (pageChange)="page = $event"
          ></pagination-controls>
        </div>
      </div>
      <!--/.Card-->
    </div>
  </div>
</div>
<app-add-product></app-add-product>
