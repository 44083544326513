import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from "./../../../shared/services/translate.service";
import { AuthService } from "./../../../shared/services/auth.service";
import { PurchaseOrderService } from 'src/app/shared/services/purchaseorder.service';
import { PurchaseOrder } from 'src/app/shared/models/purchaseorder';
import { MatDialog } from '@angular/material/dialog';
import { PoDialogComponent } from './po-dialog/po-dialog-component';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { forkJoin } from 'rxjs';
import { SnapshotAction } from '@angular/fire/database';
import { User } from 'src/app/shared/models/user';

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'app-po',
  templateUrl: './po.component.html',
  styleUrls: ['./po.component.scss']
})
export class PoComponent implements AfterViewInit  {

  displayedColumns: string[] = ['id', 'name', 'date', 'price', 'status', 'action'];
  poArray: PurchaseOrder[] = [];
  dataSource: MatTableDataSource<PurchaseOrder> = new MatTableDataSource(this.poArray);
  PAYMENT_COLORS: { [id: string] : string; } = {
    'Pending': 'red',
    'Done': 'green'
  };
  title: string = "Orders";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public poService: PurchaseOrderService,
    public dialog: MatDialog
  ) {
    this.authService.user$.subscribe((currentUser: User) => {
      if (currentUser.isAdmin) {
        this.title = "All Orders";
      }
      else {
        this.title = "My Orders";
      }
      this.poService.getPurchaseOrders().snapshotChanges().subscribe(
        (pos) => {
          this.poArray.length = 0;
          pos.forEach((po) => {
            const poData = {...po.payload.toJSON(), $key: po.key} as PurchaseOrder;
            var addPO = true;
            if (!currentUser.isAdmin && poData.purchaserKey != currentUser.$key) {
              addPO = false;
            }
            if (addPO) {
              this.poArray.push(poData);
            }
          });
          this.dataSource = new MatTableDataSource(this.poArray);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      );
    });

  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(po : PurchaseOrder): void {
    const dialogRef = this.dialog.open(PoDialogComponent, {
      width: '600px',
      data: {poID: po.id, currentStatus: po.paymentStatus},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === po.id) {
        console.log('The dialog was closed with result:' + result);
        var poStatusToUpdate = po.paymentStatus == "Pending" ? "Done" : "Pending";
        console.log("Status to change to " + poStatusToUpdate + " for " + JSON.stringify(po));
        po.paymentStatus = poStatusToUpdate;
        this.poService.updatePurchaseOrder(po, (updatedData) => {
          this.toastrService.success("PO Update", "PO Updated successfully");
        }, (err) => {
          this.toastrService.error("PO Update", "PO Update failed");
        });
      }
    });
  }
}
