import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  carouselList = [
/*     {
      bannerImg: "./assets/banner_img/homevisit.jpg",
      title: "Home Visit",
      description: "We visit your home to do the prescribed tests",
    },
    {
      bannerImg: "./assets/banner_img/labtests.jpg",
      title: "Laboratory Services",
      description:
        "We do detailed tests in our lab",
    },
 */    {
      bannerImg: "./assets/banner_img/labtests_01.jpg",
      title: "Lab Test 01",
      description: "We do detailed tests in our lab",
    },
    {
      bannerImg: "./assets/banner_img/labtests_02.jpg",
      title: "Lab Test 02",
      description: "We do detailed tests in our lab",
    },
    {
      bannerImg: "./assets/banner_img/labtests_03.jpg",
      title: "Lab Test 03",
      description: "We do detailed tests in our lab",
    },
    {
      bannerImg: "./assets/banner_img/labtests_04.jpg",
      title: "Lab Test 04",
      description: "We do detailed tests in our lab",
    },
    {
      bannerImg: "./assets/banner_img/labtests_05.jpg",
      title: "Lab Test 05",
      description: "We do detailed tests in our lab",
    },
    {
      bannerImg: "./assets/banner_img/labtests_06.jpg",
      title: "Lab Test 06",
      description: "We do detailed tests in our lab",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
