import { Product } from "./product";
import { User } from "./user";
export class PurchaseOrder {
    $key: string;
    id: string;
    products: string[];
    productsPrice: number;
    tax: number;
    totalPrice: number;
    purchaseDate: number;
    purchaserKey: string;
    purchaserName: string;
    paymentStatus: string;
}
