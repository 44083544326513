import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    poID: string;
    currentStatus: string;
}
  
@Component({
    selector: 'app-po-dialog',
    templateUrl: './po-dialog-component.html',
    styleUrls: ['./po-dialog-component.scss']
  })
  export class PoDialogComponent {
    constructor(
      public dialogRef: MatDialogRef<PoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  }
