<!--Navbar-->
<nav class="navbar navbar-expand-lg navbar-dark">
  <!-- Navbar brand -->
  <a class="navbar-brand" href="javascript:;;" [routerLink]="['/']">
    <img src="./assets/img/logo_small.jpeg" width="64" height="64" />
  </a>
  <img src="./assets/img/iso_certified.jpeg" width="64" height="32" />

  <!-- Collapse button -->
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#basicExampleNav"
    aria-controls="basicExampleNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Collapsible content -->
  <div class="collapse navbar-collapse" id="basicExampleNav">
    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="javascript:;;" [routerLink]="['/']"
          >{{ this.translate.data["HOME"] | translate }}
          <span class="sr-only">(current)</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/products/all-products']"
          >{{ this.translate.data["PRODUCTS"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/task-board/tasks']"
          >{{ this.translate.data["TASKBOARD"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Pricing</a>
      </li> -->
      <li class="nav-item dropdown" dropdown>
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
        {{ this.translate.data["HOMEVISIT"] | translate }}</a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary"
          role="menu"
        >
          <a
            class="dropdown-item waves-light"
            href="jaascript:;;"
            [routerLink]="['/products/categorized', 'Home Lab Tests']"
            >{{ this.translate.data["LABSERVICES"] | translate }}</a
          >
        </div>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
        {{ this.translate.data["SERVICES"] | translate }}</a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary"
          role="menu"
        >
          <a
            class="dropdown-item waves-light"
            href="jaascript:;;"
            [routerLink]="['/products/imaging-radiology']"
            >{{ this.translate.data["IMAGINGRADIOLOGY"] | translate }}</a
          >
          <a
            class="dropdown-item waves-light"
            href="jaascript:;;"
            [routerLink]="['/products/categorized', 'General Lab Tests']"
            >{{ this.translate.data["LABSERVICES"] | translate }}</a
          >
        </div>
      </li>
    </ul>
    <!-- Links -->

    <ul class="navbar-nav ml-auto">
<!--       <li class="nav-item">
        <div>
          <a
            class="nav-link"
            href="javascript:;;"
            [routerLink]="['/products/favourite-products']"
          >
            <i class="fa fa-heart">
              <span class="mt-2">
                {{ productService.getLocalFavouriteProducts().length }}</span
              >
            </i>
          </a>
        </div>
      </li> -->
      <li class="nav-item" *ngIf="authService.isLoggedIn$ | async">
        <div>
          <a
            class="nav-link"
            href="javascript:;;"
            [routerLink]="['/products/cart-items']"
          >
            <i class="fa fa-shopping-cart">
              <span class="mt-2">
                {{ productService.getLocalCartProducts().length }}</span
              >
            </i>
          </a>
        </div>
      </li>
      <li class="nav-item" *ngIf="authService.isLoggedIn$ | async">
        <div>
          <a
            class="nav-link"
            href="javascript:;;"
            [routerLink]="['/po']"
          >
            <i class="fa fa-heartbeat">
            </i>
          </a>
        </div>
      </li>
      <!-- <li class="nav-item dropdown" dropdown>
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
          <i class="fa fa-paint-brush"> </i><span class="caret"></span
        ></a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary color-dropdown"
          role="menu"
        >
          <div class="row p-2" style="margin: 0;">
            <ng-container
              *ngFor="let theme of colorPallet1"
              [ngTemplateOutlet]="themeTemplate"
              [ngTemplateOutletContext]="{
                item: theme
              }"
            ></ng-container>
          </div>
          <div class="row p-2" style="margin: 0;">
            <ng-container
              *ngFor="let theme of colorPallet2"
              [ngTemplateOutlet]="themeTemplate"
              [ngTemplateOutletContext]="{
                item: theme
              }"
            ></ng-container>
          </div>
        </div>
      </li> -->
      <li class="nav-item dropdown" dropdown>
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
          <i class="fa fa-globe"> </i><span class="caret"></span
        ></a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary"
          role="menu"
        >
          <a
            class="dropdown-item waves-light"
            href="javascript:;;"
            (click)="setLang(lang.langCode)"
            *ngFor="let lang of languageList"
          >
            <i class="fa fa-flag-o mr-2"></i>
            {{ lang.language }}</a
          >
        </div>
      </li>

      <li class="nav-item dropdown" dropdown *ngIf="authService.isAdmin$ | async">
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
          <i class="fa fa-user-secret"> </i><span class="caret"></span
        ></a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary"
          role="menu"
        >
          <a class="dropdown-item waves-light" [routerLink]="['/admin/order-categories', 'Home Lab Tests']">
            <i class="fa fa-random mr-1"></i
            >{{ this.translate.data["ORDERCATS_HOME"] | translate }}</a
          >
          <a class="dropdown-item waves-light" [routerLink]="['/admin/order-categories', 'General Lab Tests']">
            <i class="fa fa-random mr-1"></i
            >{{ this.translate.data["ORDERCATS_GENERAL"] | translate }}</a
          >
          <a class="dropdown-item waves-light" [routerLink]="['/admin/all-products']">
            <i class="fa fa-cogs mr-1"></i
            >{{ this.translate.data["ALLPRODUCTS"] | translate }}</a
          >
        </div>
      </li>

      <li class="nav-item" *ngIf="authService.isLoggedIn$ | async">
        <a class="nav-link" [routerLink]="['/users']">
          <i class="fa fa-user mr-1"></i
          >{{ this.translate.data["MYACCOUNT"] | translate }}</a
        >
      </li>
      <!-- <li class="nav-item" *ngIf="authService.isLoggedOut$ | async">
        <a class="nav-link" [routerLink]="['/login']">
          <i class="fa fa-sign-in mr-1"></i>
          {{ this.translate.data["SIGNIN"] | translate }}</a
        >
      </li>
      <li class="nav-item" *ngIf="authService.isLoggedIn$ | async">
        <a class="nav-link" (click)="logout()">
          <i class="fa fa-sign-in mr-1"></i
          >{{ this.translate.data["LOGOUT"] | translate }}</a
        >
      </li> -->
      <!-- <li class="nav-item">
        <a href="javascript:;;" class="nav-link"
          >Angular v-{{ angularVersion.full }}</a
        >
      </li> -->
    </ul>
  </div>
  <!-- Collapsible content -->
</nav>
<!--/.Navbar-->

<!-- Theme Template -->
<ng-template #themeTemplate let-item="item">
  <div
    class="col-sm hover-effect"
    data-toggle="tooltip"
    data-placement="bottom"
    [title]="item.title"
    style="padding: 0;"
  >
    <div class="color-container">
      <div
        class="colorDiv {{ item.color }}"
        (click)="updateTheme(item.id)"
      ></div>
    </div>
  </div>
</ng-template>
