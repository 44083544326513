<div style="position: relative; float: right; display: grid;">
  <a
  name=""
  id=""
  class="btn btn-primary"
  href="javascript:;;"
  role="button"
  (click)="downloadReceipt()"
  ><i class="fa fa-download"></i> Receipt</a
  >
  <button
  type="button"
  style="margin-top: 10px;"
  class="btn btn-success waves-light"
  data-toggle="modal"
  data-target="#paymentModalLong"
  >
    <i class="fa fa-credit-card"></i> Pay Now
  </button>
</div>

<div class="mt-5 mb-5" id="receipt">

  <div class="row">
    <div
      class="well col-xs-10 col-sm-10 col-md-6 col-xs-offset-1 col-sm-offset-1 col-md-offset-3"
      style="margin: auto;"
    >
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6">
          <img src="./assets/img/logo_small.jpeg" width="64" height="64" />
          <address>
            <strong>anlidiagnostics</strong>
            <br />
            Tamil Nadu, IN 600094
            <br />
            <abbr title="Phone">P:</abbr> (+91) 8124794942
          </address>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 text-right">
          <p>
            <em> {{ date | date }}</em>
          </p>
          <p>
            Receipt #: <em> {{ orderNumber }}</em>
          </p>
          <p>
            Payment: <em> {{ paymentStatus }}</em>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h1>Receipt</h1>
        </div>
        <table class="table table-hover nowrap">
          <thead>
            <tr>
              <th>Test</th>
              <td></td>
              <th class="text-center">Price</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of products">
              <td class="col-md-9">
                <h6>
                  <em>{{ product.productName }}</em>
                </h6>
              </td>
              <td></td>
              <td class="col-md-1 text-center">₹ {{ product.productPrice }}</td>
              <td class="col-md-1 text-center">₹ {{ product.productPrice }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td class="text-right">
                <p>
                  <strong>Subtotal: </strong>
                </p>
                <p>
                  <strong>Tax (12%): </strong>
                </p>
              </td>
              <td class="text-center">
                <p>
                  <strong>₹ {{ totalPrice }}</strong>
                </p>
                <p>
                  <strong>₹ {{ tax }}</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td class="text-right">
                <p><strong>Total: </strong></p>
              </td>
              <td class="text-right text-danger">
                <h4>
                  <strong>₹ {{ totalPrice + tax }}</strong>
                </h4>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
<!--       <div class="row">
        <button type="button" class="btn btn-success btn-lg btn-block">
          Pay Now <span class="glyphicon glyphicon-chevron-right"></span>
        </button>
      </div> -->
    </div>
  </div>
</div>
<app-payment></app-payment>