<!-- Modal -->
<div
  class="modal fade"
  id="paymentModalLong"
  tabindex="-1"
  role="dialog"
  aria-labelledby="paymentModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentModalLongTitle">Payment</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3 text-center">
            <img src="./assets/img/upi_qrcode.jpeg" width="256" height="256" />
          </div>
  
          <div class="mb-3">
            <label>Paying to <b style="font-weight: bold">9894450282@upi</b> (SAMARTHAN A)</label>
          </div>

          <div class="mb-3">
            <label><i>Enter the order ID in notes for quick payment verification!</i></label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
