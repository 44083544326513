import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Product } from "../../../../../shared/models/product";
import { PurchaseOrder } from "src/app/shared/models/purchaseorder";
import { ProductService } from "../../../../../shared/services/product.service";
import { PurchaseOrderService } from "src/app/shared/services/purchaseorder.service";
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";

declare var $: any;
const shortId = require("shortid");

@Component({
  selector: "app-result",
  templateUrl: "./result.component.html",
  styleUrls: ["./result.component.scss"],
})
export class ResultComponent implements OnInit, OnChanges {
  @Input() purchaseOrder: PurchaseOrder;
  private sub: any;
  products: Product[];
  orderNumber: string;
  paymentStatus: string;
  date: number;
  totalPrice = 0;
  tax = 6.4;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private purchaseOrderService: PurchaseOrderService
  ) {
    /* Hiding Billing Tab Element */
    // document.getElementById("productsTab").style.display = "none";
    // document.getElementById("shippingTab").style.display = "none";
    // document.getElementById("billingTab").style.display = "none";
    document.getElementById("resultTab").style.display = "block";

    // this.orderNumber = "ADPO_" + shortId.generate();
    // this.products = productService.getLocalCartProducts();

    // this.products.forEach((product) => {
    //   this.totalPrice += product.productPrice;
    // });
    // this.tax = 0.12 * this.totalPrice;

    // this.date = Date.now();
  }

  ngOnInit() {
    console.log("ngOnInit called");
    this.sub = this.route.params.subscribe((params) => {
      if (params.purchaseOrderID) {
        console.log(params.purchaseOrderID);
        var purchaseOrderObservable = this.purchaseOrderService.getPurchaseOrderById(params.purchaseOrderID);
        purchaseOrderObservable.snapshotChanges().subscribe(
          (purchaseOrderSnapshot) => {
            var purchaseOrder = purchaseOrderSnapshot.payload.val();
            this.orderNumber = purchaseOrder.id;
            this.paymentStatus = "Pending";
            this.products = [];
            purchaseOrder.products.forEach( (productKey) => {
              var productObservable = this.productService.getProductById(productKey);
              productObservable.snapshotChanges().subscribe(
                (productSnapshot) => {
                  var productFromSnapshot = productSnapshot.payload.val();
                  this.products.push(productFromSnapshot);
                }
              );
            });
            this.totalPrice = purchaseOrder.productsPrice;
            this.tax = purchaseOrder.tax;
            this.date = purchaseOrder.purchaseDate;
          }
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const dataChanges: SimpleChange = changes.purchaseOrder;

    const purchaseOrder: PurchaseOrder = dataChanges.currentValue;

    this.orderNumber = purchaseOrder.id;
    purchaseOrder.products.forEach( (productKey) => {
      var productObservable = this.productService.getProductById(productKey);
      productObservable.snapshotChanges().subscribe(
        (productSnapshot) => {
          var productFromSnapshot = productSnapshot.payload.val();
          this.products.push(productFromSnapshot);
        }
      );
    });
    this.totalPrice = purchaseOrder.productsPrice;
    this.tax = purchaseOrder.tax;
    this.date = purchaseOrder.purchaseDate;

  }

  downloadReceipt() {
    const data = document.getElementById("receipt");
    // console.log(data);

    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      // const imgWidth = 208;
      // const pageHeight = 295;
      const imgWidth = 208;
      const pageHeight = 500;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      const pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("anlidiagnostics.pdf"); // Generated PDF
    });
  }
}
