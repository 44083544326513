<br />
<div class="container" *ngIf="homeVisit">
  For Home Visits, Whatsapp <a aria-label="WhatsApp" href="https://wa.me/919345096877">9345096877</a> or call <a href="tel:+914171299003">+91 4171 299003</a>!
</div>
<div class="container">
  <div class="row" *ngIf="showMenu">
    <div class="col-sm">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary btn-lg waves-light mr-1"
          data-toggle="modal"
          data-target="#exampleModalLong"
          *ngIf="authService.isAdmin$ | async"
        >
          Create Test
        </button>
        <button
        type="button"
        class="btn btn-primary btn-lg waves-light mr-1"
        [disabled]="selectedProducts.length == 0"
        (click)="bookTestsAndCheckout()"
        >
        Book Tests
        </button>
        <button
          type="button"
          class="btn btn-primary btn-lg waves-light mr-1"
          [disabled]="selectedProducts.length == 0"
          (click)="clearAll()"
          >
          Clear All
        </button>
    </div>
    </div>
  </div>
  <div class="row" *ngIf="!homeVisit">
    <div class="col-md-12">
      <div class="container">
        <app-card-loader
          [loop]="3"
          [height]="800"
          *ngIf="loading"
        ></app-card-loader>
        <div class="row" *ngIf="!loading">
          <!-- List of Products -->
          <!-- <div
            class="col-sm-4 mb-2"
            *ngFor="
              let product of productList
                | filterByBrand: selectedBrand
                | paginate: { itemsPerPage: 6, currentPage: page }
            "
          > -->
          <!-- <div
            class="col-sm-4 mb-2"
            *ngFor="
              let categoryProduct of categorizedProducts | keyvalue
                | paginate: { itemsPerPage: 6, currentPage: page }
            "
          > -->
          <div
            class="col-sm-4 mb-2"
            *ngFor="
              let categoryProduct of categoryProducts
                | paginate: { itemsPerPage: 6, currentPage: page }
            "
          >
            <div class="item">
              <div class="mb-r">
                <!--Card-->
                <div class="card card-cascade wider">
                  <!--Card content-->
                  <div class="card-body no-padding">
                    <!--Category & Title-->
                    <div class="text-center text-muted">
                      <h5><u>{{ categoryProduct.category }}</u></h5>
                    </div>
                    <div *ngFor="let product of categoryProduct.products">
                      <input type="checkbox" *ngIf="showMenu" [(ngModel)]="productSelectionStates[product.productId]" (click)="onSelect(product)" [id]="product.productId" />
                      <label class="pl-2" [for]="product.productId">{{product.productName}}</label>
                    </div>
                    <!-- Description -->
                    <!-- <p class="card-text">{{ product.productDescription }}</p> -->
                  </div>
                  <!--/.Card content-->
                </div>
                <!--/.Card-->
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-3">
        </div>
        <!-- Pagination -->
        <div class="text-center mt-3">
          <pagination-controls
            (pageChange)="page = $event"
          ></pagination-controls>
        </div>
      </div>
      <!--/.Card-->
    </div>
  </div>
</div>
<app-add-product></app-add-product>
