<section>
  <div class="container">
    <div class="board">
      <!-- Navigation Area (Circular Tabs) -->
      <app-checkout-navbar></app-checkout-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <!-- Routed view  -->
        <router-outlet name="checkOutlet"></router-outlet>
      </div>
      <!-- End Content Area -->
    </div>

    <!-- For Debugging: show our formData as it is being typed -->
    <!-- <pre>{{ formData | json }}</pre> -->
  </div>
</section>
