<p></p>
<div class="container">
    <div class="row">
      <div class="col-sm-4">
        <p>
          <img src="./assets/img/ctscan.jpg" vspace="0" width="497" height="400" hspace="0" align="left" class="img-responsive img-thumbnail" alt="">
        </p>
      </div>
      <div class="col-sm-8">
        <p>Anli Diagnostics imaging services focus on supporting the clinicians to diagnose the ailments in a precise manner, with the help of advanced radiological technologies. Anli Radiology team, is a very trusted brand for the clinicians to interpret their patient clinical data, and making it possible to deliver a better service to there patients.&nbsp;Anli Radiology department is equipped with 3D multislice CT Scan, Ultrasonogram &amp; other basic radiological equipments.</p>
        <p>Anli Diagnostics radiology team with the required expertise, knowledge and the advanced equipments makes it possible to diagnose the minute findings, in the patients. The radio diagnosis is a field where excellent clinical skills and wide medical knowledge is mandated to make the diagnosis precise.</p>
        <p>Anli Diagnostics, with its technically expert team is able to excel in special diagnostic procedures, especially in the field of obstetrics and gynecology, and several contrast interventional procedures. In the field of radio diagnosis, each and every diagnosis is very important in patient's life , which is very well dealt by us.&nbsp;</p>      
      </div>
    </div>
  </div>
