<h4 class="d-flex justify-content-between align-items-center mb-3">
  <span class="text-muted">Your selected Tests</span>
  <span class="badge badge-primary badge-pill">{{ products.length }}</span>
</h4>
<ul class="list-group mb-3">
  <li
    class="list-group-item d-flex justify-content-between lh-condensed"
    *ngFor="let product of products"
  >
    <div>
      <a
      placement="top"
      mdbTooltip="Quick Look"
      container="body"
      [routerLink]="['/products/product', product.$key]"
      >
        <i class="fa fa-eye"></i>
      </a>
      <a
      placement="top"
      mdbTooltip="Remove Test"
      container="body"
      (click)="removeCartProduct(product)"
      >
        <i class="fa fa-trash"></i>
      </a>
    </div>
    <div>
      <h6 class="my-0">{{ product.productName }}</h6>
    </div>
    <span class="text-muted">₹ {{ product.productPrice }}</span>
  </li>
  <hr />
  <li class="list-group-item d-flex justify-content-between">
    <span>Total (INR)</span>
    <strong>₹ {{ totalValue }}</strong>
  </li>
  <hr />
  <li class="list-group-item d-flex justify-content-between">
    <a class="btn btn-primary" [routerLink]="['/products/all-products']"
    >Continue Selecting Tests</a
    >
    <button class="btn btn-primary" [disabled]="(authService.isLoggedIn$ | async)===false" (click)="initiatePurchase()">Purchase</button>
  </li>
  <li *ngIf="(authService.isLoggedIn$ | async)===false">
    <span style="color:red"><i>You need to be logged-in to continue purchase, thanks!</i></span>
  </li>
</ul>
