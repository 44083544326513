<!-- Modal -->
<div
  class="modal fade"
  id="exampleModalLong"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
  (focus)="onFormFocus()"
>
  <input type="text" name="editID" id="editID" value="" style="display:none"
  [(ngModel)]="editID"
  />
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Test</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="onFormClose()"
          >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          class="needs-validation"
          #addProductForm="ngForm"
          (ngSubmit)="createProduct(addProductForm)"
        >
          <div class="mb-3">
            <label for="productName">Test Name</label>
            <input
              type="text"
              class="form-control"
              id="productName"
              name="productName"
              [(ngModel)]="product.productName"
              placeholder="Test Name"
              value=""
              required
            />
            <div class="invalid-feedback">
              Valid test name is required.
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="productCategory">Test Category</label>
              <input
                type="text"
                class="form-control"
                id="productCategory"
                name="productCategory"
                [(ngModel)]="product.productCategory"
                placeholder="Test Category"
                value=""
                required
              />
              <div class="invalid-feedback">
                Valid test category is required.
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="productSubCategory">Test Sub-Category</label>
              <input
                type="text"
                class="form-control"
                id="productSubCategory"
                name="productSubCategory"
                [(ngModel)]="product.productSubCategory"
                placeholder="Test Sub-Category"
                value=""
                required
              />
              <div class="invalid-feedback">
                Valid test sub-category is required.
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="productDescription"
              >Test Description
              <span class="text-muted">(Optional)</span>
            </label>
            <textarea
              type="text"
              class="form-control"
              id="productDescription"
              placeholder="Test Description"
              name="productDescription"
              [(ngModel)]="product.productDescription"
            ></textarea>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="productPrice">Test Price</label>
              <input
                type="number"
                name="productPrice"
                class="form-control"
                [(ngModel)]="product.productPrice"
                placeholder="Test Price"
                id="productPrice"
                value=""
                required
              />
              <div class="invalid-feedback">
                Valid test price is required.
              </div>
            </div>
<!--             <div class="col-md-6 mb-3">
              <label for="productQuatity">Test Quantity</label>
              <input
                type="text"
                class="form-control"
                id="productQuatity"
                name="productQuatity"
                [(ngModel)]="product.productQuatity"
                placeholder="Test Quantity"
                value=""
                required
              />
              <div class="invalid-feedback">
                Valid test quantity is required.
              </div>
            </div> -->
          </div>

          <div class="mb-3">
            <label for="productSeller">Test Provider</label>
            <input
              type="text"
              class="form-control"
              id="productSeller"
              [(ngModel)]="product.productSeller"
              name="productSeller"
              placeholder="Test Provider"
              required
            />
            <div class="invalid-feedback">
              Valid test seller is required.
            </div>
        </div>
<!--           <div class="mb-3">
            <label for="productImageUrl">Test Image</label>
            <input
              type="text"
              class="form-control"
              id="productImageUrl"
              [(ngModel)]="product.productImageUrl"
              name="productImageUrl"
              placeholder="Image Url"
              required
            />
          </div> -->

          <hr class="mb-4" />

          <button class="btn btn-primary btn-lg btn-block" id="productSubmit" type="submit">
            Create Test
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
