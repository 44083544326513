<h3 class="text-center">Payment &amp; Receipt</h3>

<div class="board-inner" id="status-buttons">
  <ul class="nav nav-tabs" id="myTab">
    <div class="liner"></div>

    <!-- circular user icon -->
<!--     <li id="productsTab" style="margin: auto;">
      <a
        [routerLink]="['/checkouts', { outlets: { checkOutlet: ['/'] } }]"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        data-toggle="tab"
        title="Product Summary"
      >
        <span class="round-tabs one">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        </span>
      </a>
    </li>
 -->
    <!-- circular tasks icon -->
<!--     <li id="shippingTab" style="margin: auto;">
      <a
        [routerLink]="[
          '/checkouts',
          { outlets: { checkOutlet: ['shipping-details'] } }
        ]"
        routerLinkActive="active"
        data-toggle="tab"
        title="Shipping Details"
      >
        <span class="round-tabs two">
          <i class="fa fa-truck" aria-hidden="true"></i>
        </span>
      </a>
    </li>
 -->
    <!-- circular home icon -->
<!--     <li id="billingTab" style="margin: auto;">
      <a
        [routerLink]="[
          '/checkouts',
          { outlets: { checkOutlet: ['billing-details'] } }
        ]"
        routerLinkActive="active"
        data-toggle="tab"
        title="Confirmation"
      >
        <span class="round-tabs three">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </span>
      </a>
    </li>
 -->
    <!-- circular ok icon -->
    <li id="resultTab" style="margin: auto;">
      <a
        [routerLink]="['/checkouts', { outlets: { checkOutlet: ['result'] } }]"
        routerLinkActive="active"
        data-toggle="tab"
        title="Payment"
      >
        <span class="round-tabs four">
          <i class="fa fa-credit-card" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
  <div class="clearfix"></div>
</div>
