<br />
<div class="container">
  <div class="row">
    <div class="col-sm-4">
      <div class="product-image">
        <div class="view hm-zoom z-depth-2" style="cursor: pointer;">
<!--           <img
            [src]="product.productImageUrl"
            [alt]="product.productName"
            width="100%"
            class="img-fluid rounded"
          /> -->
          {{ product.productName }}
        </div>
        <div class="" style="margin-top: 15px;">
          <ul class="list-group mb-3">
            <li
              class="list-group-item d-flex justify-content-between lh-condensed"
            >
              <div>
                <h6 class="my-0">Test Price</h6>
              </div>
              <span class="text-muted" style="color: crimson !important;"
                >₹ {{ product.productPrice }}</span
              >
            </li>
            <li
              class="list-group-item d-flex justify-content-between lh-condensed"
            >
              <div>
                <h6 class="my-0">Test Provider</h6>
              </div>
              <span class="text-muted" style="color: crimson !important;">{{
                product.productSeller
              }}</span>
            </li>
          </ul>
          <button class="btn btn-primary" (click)="addToCart(product)">
            Add Test
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
      <div class="product-detail">
        <h5 class="product-head">Test Details</h5>
        <table class="table" cellspacing="0" style="max-height: 28px;">
          <tbody>
            <tr>
              <th scope="row">Test Name</th>
              <td>{{ product.productName }}</td>
            </tr>
            <tr>
              <th scope="row">Test Description</th>
              <td>{{ product.productDescription }}</td>
            </tr>
            <tr>
              <th scope="row">Test Category</th>
              <td>{{ product.productCategory }}</td>
            </tr>
            <tr>
              <th scope="row">Test Type</th>
              <td>{{ product.productSubCategory }}</td>
            </tr>
<!--             <tr>
              <th scope="row">No. of quantity available</th>
              <td>{{ product.productQuatity }}</td>
            </tr> -->
            <tr *ngIf="authService.isAdmin$ | async">
              <th scope="row">Test Added on</th>
              <td>{{ product.productAdded * 1000 | date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
