import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { SharedModule } from "../../../shared/shared.module";

import { AdminComponent } from './admin.component';
import { OrderCategoriesComponent } from './order-categories/order-categories.component';

// configuration and services
import { AdminRoutes } from "./admin.routing";
// import { AdminRoutingModule } from './admin-routing.module';

@NgModule({
  declarations: [
    AdminComponent,
    OrderCategoriesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminRoutes),
    SharedModule,
    // AdminRoutingModule
  ]
})
export class AdminModule { }
