import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "./../../../../shared/services/auth.service";
import { ProductService } from "../../../../shared/services/product.service";
import { ProductCategoryOrderService } from 'src/app/shared/services/productcategoryorder.service';
import { ToastrService } from "src/app/shared/services/toastr.service";
import { Product } from "../../../../shared/models/product";
import { ProductCategoryOrder } from 'src/app/shared/models/productcategoryorder';

@Component({
  selector: 'app-order-categories',
  templateUrl: './order-categories.component.html',
  styleUrls: ['./order-categories.component.scss']
})
export class OrderCategoriesComponent implements OnInit {

  private sub: any;
  loading = false;
  productCategories = [];
  private categoryKeyFromDB: string;
  private category: string;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private productService: ProductService,
    private productCategoryOrderService: ProductCategoryOrderService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      if (params.category) {
        this.category = params.category;
        console.log(params.category);
        const category = params.category;
        this.getAllProducts(category);
      }
      else {
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.productCategories, event.previousIndex, event.currentIndex);
  }

  getAllProducts(category) {
    this.loading = true;
    const allProductCategoryOrders = this.productCategoryOrderService.getProductCategoryOrders();
    allProductCategoryOrders.snapshotChanges().subscribe(
      (categoryOrders) => {
        this.productCategories = [];
        categoryOrders.forEach((element) => {
          const categoryOrder = { ...element.payload.toJSON(), $key: element.key};
          const item = (categoryOrder as ProductCategoryOrder);
          if (item.id === this.category) {
            this.categoryKeyFromDB = item.$key;
            this.productCategories = this.productCategories.concat(Object.values(item.categories));
          }
        })
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
    const x = this.productService.getProducts();
    x.snapshotChanges().subscribe(
      (product) => {
        this.loading = false;
        product.forEach((element) => {
          const y = { ...element.payload.toJSON(), $key: element.key };
          const productCategory = (y as Product).productCategory;
          const productSubCategory = (y as Product).productSubCategory;
          if (productCategory === category && this.productCategories.indexOf(productSubCategory) === -1){
            this.productCategories.push(productSubCategory);
          }
        });
      },
      (err) => {
        this.toastrService.error("Error while fetching Products", err);
      }
    );
  }

  save() {
    const productCategoryOrder: ProductCategoryOrder = new ProductCategoryOrder();
    productCategoryOrder.$key = this.categoryKeyFromDB;
    productCategoryOrder.id = this.category;
    productCategoryOrder.categories = this.productCategories;
    this.productCategoryOrderService.createOrUpdateProductCategoryOrder(productCategoryOrder, () => {
      this.toastrService.success("Save Category Order status", "Save Category Order succeeded");
      this.getAllProducts(this.category);
    }, () => {
      this.toastrService.error("Save Category Order status", "Failed to save category order!");
    });
  }

}
