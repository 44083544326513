<div class="container" *ngIf="authService.isAdmin$ | async">
    <app-card-loader
        [loop]="3"
        [height]="800"
        *ngIf="loading"
    ></app-card-loader>
    <div class="row" *ngIf="!loading && productCategories.length != 0">
        <div class="col-sm">
          <div class="float-right">
                <button
                type="button"
                class="btn btn-primary btn-lg waves-light mr-1"
                (click)="save()"
                >
                Save
                </button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!loading && productCategories.length != 0">
        <p>Drag and drop test categories up or down to reflect the order</p>
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let productCategory of productCategories" cdkDrag>{{productCategory}}</div>
        </div>
    </div>
    <div class="row" *ngIf="!loading && productCategories.length == 0">
        <p>No tests added yet!</p>
    </div>
</div>
