<!--Footer-->
<footer class="page-footer indigo center-on-small-only mt-3">
  <!--Copyright-->
  <div class="footer-copyright">
    <div class="container-fluid">
      © 2022 Copyright:
      <a href="mailto:anli.diagnostics@gmail.com"> Anli Diagnostics </a>

      <span style="float: right;">
        <i class="fa fa-globe"></i>
        Developer:
        <a href="https://github.com/kaniyuga" target="blank">Kaniyuga</a>
      </span>
    </div>
  </div>
  <!--/.Copyright-->
</footer>
<!--/.Footer-->
